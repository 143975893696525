import React from "react"
import get from "lodash.get"
import marked from "marked"
import Helmet from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

import Markdown from "@components/markdown"
import Link from "@components/swipe-link"
import SEO from "@components/seo"
import Image from "@components/image"
import Enter from "@components/animate-entry"
import IconArrowLeft from "@images/icon-arrow-left.svg"
import { monthYear, generateTeaser, yearMonthDate } from "@utils/string"
import { makePath } from "@utils/url"
import { ogUrl, imageUrl } from "@utils/image"

// Import share button component
import ShareButtons from "../../components/share-icons/Share"

// Add mailchimp in sidebar
import MailChimpSidebar from "@components/mailchimp-sidebar"

import StickyBox from "react-sticky-box"

import "./index.scss"

const BlogPost = ({ pageContext, location }) => {
  const { post, recs } = pageContext

  const currentUrl = location.href

  const heroCaption = get(post, "heroImage.description.description")

  const socialImage = ogUrl(post.heroImage.file.url)

  const {
    site: { siteMetadata: meta },
  } = useStaticQuery(graphql`
    query PostMeta {
      site {
        siteMetadata {
          description
          title
          siteUrl
        }
      }
    }
  `)

  const jsonLd = {
    "@context": "https://schema.org/",
    "@type": "BlogPosting",
    articleBody: marked(post.body.body),
    datePublished: post.datePublished,
    headline: post.title,
    author: post.project ? `${post.project.name}` : "Acubed",
    image: socialImage,
    publisher: {
      "@type": "Organization",
      name: meta.title,
      description: meta.description,
      url: meta.siteUrl,
      logo: `${meta.siteUrl}/icons/icon-144x144.png`,
    },
  }

  return (
    <section className="blog-post">
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>
      <SEO
        title={`${post.title}`}
        description={generateTeaser(post.body.body, 160)}
        image={socialImage}
      />
      <Enter>
        <article
          className="blog-post__hero"
          style={{
            background: `#fff url(${imageUrl(post.heroImage.file.url, {
              q: 85,
              w: 1800,
            })}) center center/cover no-repeat`,
          }}
        >
          <div className="blog-post__hero-content u-layout-max-width">
            <Link
              reverse
              to="/blog"
              className="button button-tertiary button-tertiary-left button-tertiary-blog"
            >
              <div className="button-tertiary__content-wrap">
                <IconArrowLeft className="button-tertiary__icon" />
                <span className="button-tertiary__text">News</span>
              </div>
            </Link>
          </div>
        </article>
      </Enter>
      {/* hero */}
      {heroCaption ? (
        <Enter>
          <article className="u-layout-max-width blog-post-caption">
            <p className="caption blog-post-caption__caption">{heroCaption}</p>
          </article>
        </Enter>
      ) : (
        <div style={{ marginBottom: "104px" }} />
      )}
      {/* article */}
      <Enter delay={200}>
        <article className="blog-post-article u-layout-max-width">
          {/* date */}
          <aside className="blog-post-date">
            <div className="blog-post-date__month-year">
              {monthYear(post.datePublished)}
            </div>
            <div className="blog-post-date__day number">
              {yearMonthDate(post.datePublished)[2]}
            </div>
          </aside>
          <div>
            <div
              className="blog-post-article__section"
              style={{ display: "flex", alignItems: "flex-start" }}
            >
              <div className="blog-post-article__content">
                <h1 className="blog-post-article__title">{post.title}</h1>

                <Markdown source={post.body.body} />
                <ShareButtons title={post.title} url={currentUrl} />
              </div>
              {/* <StickyBox style={{ border: "0px solid green", top: "95px" }}>
                <article className="u-layout-max-width subscribe_blk">
                  <div className="footer__subscribe">
                    <div className="subscribe_header">
                      <h4>Subscribe to our newsletter</h4>
                      <p>Keep up with our latest news and events</p>
                    </div>
                    <MailChimpSidebar className="subscribe_form" />
                  </div>
                </article>
              </StickyBox> */}
            </div>
          </div>
        </article>
      </Enter>
      {recs && recs.length === 2 && (
        <article className="blog-post-suggestions">
          {recs.map((rec, ind) => (
            <Enter delay={ind * 150} key={rec.title}>
              <Link
                to={makePath.blogPost(rec)}
                className={`blog-post-suggestions__suggestion ${
                  ind === 0 ? "prev" : "next"
                }`}
              >
                <Image
                  className="blog-post-suggestions__img"
                  {...rec.heroImage}
                  q="40"
                  lazy
                />
                <div className="blog-post-suggestions__content">
                  <div className="blog-post-suggestions__keyline" />
                  <h3>{rec.title}</h3>
                </div>
              </Link>
            </Enter>
          ))}
        </article>
      )}
    </section>
  )
}

export default BlogPost
